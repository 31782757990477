import React, { useState, useEffect } from "react";
import TVNoise from "./components/TVNoise";
import classNames from "classnames";
import styles from "./App.module.css";
import Emulator from "./components/Emulator"; 

import rom1 from '../roms/Mario.nes';
import rom2 from '../roms/Contra.nes';
import rom3 from '../roms/DonkeyKong.nes';
import rom4 from '../roms/Zelda.nes';

import rom1Image from '../roms/images/mario.webp';
import rom2Image from '../roms/images/contra.webp';
import rom3Image from '../roms/images/donkey-kong-classics.webp';
import rom4Image from '../roms/images/zelda-the-legend-of-zelda.webp';

import iconX from '../img/IconX.webp';
import iconTg from '../img/iconTg.webp';
import iconDex from '../img/IconDex.webp';

const MemoizedEmulator = React.memo(Emulator); 

const App = () => {
    const [rom, setRom] = useState(null);
    const [showGames, setShowGames] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);

	useEffect(() => {
        const handleKeyDown = (e) => {
            
            if (["ArrowUp", "ArrowDown", " "].includes(e.key)) {
                e.preventDefault();
            }
			            
						if (e.key === "Enter" && document.activeElement.tagName === "BUTTON") {
							e.preventDefault();
						}
        };

        window.addEventListener("keydown", handleKeyDown);

        
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const roms = [
        { file: rom1, image: rom1Image },
        { file: rom2, image: rom2Image },
        { file: rom3, image: rom3Image },
        { file: rom4, image: rom4Image }
    ];

    const loadRom = (rom) => {
        setRom(rom);
    };

    const onRomClick = (index) => {
        const selectedRom = roms[index].file;
        if (selectedRom) {
            fetch(selectedRom)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => {
                    loadRom(arrayBuffer);
                })
                .catch(error => {
                    console.error('Error loading ROM:', error);
                });
        }
    };

    const toggleGames = () => {
        setShowGames(prevShowGames => !prevShowGames);
    };

    const toggleViewOnly = () => {
        setViewOnly(prevViewOnly => !prevViewOnly);
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();

        if (file) {
            reader.onload = (event) => {
                loadRom(event.target.result);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const ignoreEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        window.addEventListener("dragover", ignoreEvent);
        window.addEventListener("dragenter", ignoreEvent);
        window.addEventListener("drop", handleFileDrop);

        return () => {
            window.removeEventListener("dragover", ignoreEvent);
            window.removeEventListener("dragenter", ignoreEvent);
            window.removeEventListener("drop", handleFileDrop);
        };
    }, []);

    return (
        <div className={styles.app}>
            {!viewOnly && (
                <>
                    <h4>Relive classic gaming nostalgia, immortalized on the Solana blockchain</h4>
                    <h6 className={styles.sourceCode}>
                        <a href="https://pump.fun/" target="_blank" rel="noreferrer">
                            pump.fun
                        </a>
                    </h6>
                    <h6 className={styles.subtitle}>
                        Drag a NES ROM here and use a gamepad.
                    </h6>
                    <p className={styles.subsubtitle}>
                        (or: D, [space], arrows, [enter] & [supr]) &#123;savestates: + and -&#125;
                    </p>
                </>
            )}

{!viewOnly && (          <div className={styles.socialLinks}>
                <a href="https://x.com/NesOnSol" target="_blank" rel="noopener noreferrer">
                    <img src={iconX} alt="Twitter" className={styles.icon} />
                </a>
                <a href="https://t.me/+6kI067mhGY9jNDU5" target="_blank" rel="noopener noreferrer">
                    <img src={iconTg} alt="Telegram" className={styles.icon} />
                </a>
                <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
                    <img src={iconDex} alt="DexScreener" className={styles.icon} />
                </a>
            </div>)}

{!viewOnly &&             <button className={styles.toggleButton} onClick={toggleGames}>
                {showGames ? 'Hide Games' : 'Show Games'}
            </button>}
            <button className={styles.toggleButton} onClick={toggleViewOnly}>
                {viewOnly ? 'Exit View Only' : 'Screen Only Mode'}
            </button>
{viewOnly && 			< >
                <a href="https://twitter.com/yourhandle" target="_blank" rel="noopener noreferrer">
                    <img src={iconX} alt="Twitter" className={styles.icon} />
                </a>
                <a href="https://t.me/yourhandle" target="_blank" rel="noopener noreferrer">
                    <img src={iconTg} alt="Telegram" className={styles.icon} />
                </a>
                <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
                    <img src={iconDex} alt="DexScreener" className={styles.icon} />
                </a>
            </>}

            <div className={styles.wrapper}>
                <div
                    className={classNames(
                        styles.gameContainer,
                        "nes-container",
                        "is-dark",
                        "with-title",
                        viewOnly && styles.viewOnlyMode 
                    )}
                >
                    {rom ? (
                        <MemoizedEmulator
                            rom={rom}
                            onError={(error) => console.error(error)}
                        />
                    ) : (
                        <TVNoise />
                    )}
                </div>
                <h6 id="fps">(fps: 0)</h6>

                <div
                    className={styles.modulesContainer}
                    style={{
                        maxHeight: showGames ? '500px' : '0',
                        opacity: showGames ? '1' : '0',
                        overflow: 'hidden'
                    }}
                >
                    {!viewOnly && roms.map((romData, index) => (
                        <div
                            key={index}
                            className={classNames(styles.module, "nes-container", "with-title")}
                            onClick={() => onRomClick(index)}
                            style={{
                                backgroundImage: `url(${romData.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                color: '#fff',
                                transition: 'opacity 0.5s ease, transform 0.5s ease', 
                                transform: showGames ? 'scale(1)' : 'scale(0.95)',
                                opacity: showGames ? '1' : '0',
                            }}
                        >
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default App;
